<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="userAddEditTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <form
      class="modal-content"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="modal-header">
        <h2 class="modal-title" id="userAddEditTitle">
          {{ "takeover" | i18n }}
          <small class="text-muted" *ngIf="name">{{ name }}</small>
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-callout type="warning">{{ "loggedOutWarning" | i18n }}</app-callout>
        <app-callout
          type="info"
          [enforcedPolicyOptions]="enforcedPolicyOptions"
          *ngIf="enforcedPolicyOptions"
        >
        </app-callout>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="masterPassword">{{ "newMasterPass" | i18n }}</label>
              <input
                id="masterPassword"
                type="password"
                name="NewMasterPasswordHash"
                class="form-control mb-1"
                [(ngModel)]="masterPassword"
                required
                appInputVerbatim
                autocomplete="new-password"
              />
              <app-password-strength
                [password]="masterPassword"
                [email]="email"
                [showText]="true"
                (passwordStrengthResult)="getStrengthResult($event)"
              >
              </app-password-strength>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="masterPasswordRetype">{{ "confirmNewMasterPass" | i18n }}</label>
              <input
                id="masterPasswordRetype"
                type="password"
                name="MasterPasswordRetype"
                class="form-control"
                [(ngModel)]="masterPasswordRetype"
                required
                appInputVerbatim
                autocomplete="new-password"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "save" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "cancel" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
