<ng-container [ngSwitch]="disabled">
  <a
    *ngSwitchCase="false"
    role="tab"
    [class]="baseClassList"
    [routerLink]="route"
    [routerLinkActive]="activeClassList"
    #rla="routerLinkActive"
    [attr.aria-selected]="rla.isActive"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
  <button
    *ngSwitchCase="true"
    type="button"
    role="tab"
    [class]="baseClassList"
    disabled
    aria-disabled="true"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
</ng-container>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
