<div
  class="tw-flex tw-items-center tw-gap-2 tw-py-2.5 tw-px-4 tw-text-contrast"
  [ngClass]="bannerClass"
  [attr.role]="useAlertRole ? 'status' : null"
  [attr.aria-live]="useAlertRole ? 'polite' : null"
>
  <i class="bwi tw-align-middle" [ngClass]="icon" *ngIf="icon" aria-hidden="true"></i>
  <span class="tw-grow tw-text-base">
    <ng-content></ng-content>
  </span>
  <button class="tw-border-0 tw-bg-transparent tw-p-0 tw-text-contrast" (click)="onClose.emit()">
    <i class="bwi bwi-close tw-text-sm" *ngIf="icon" aria-hidden="true"></i>
  </button>
</div>
