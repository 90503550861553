<div
  class="modal fade"
  role="dialog"
  aria-modal="true"
  aria-labelledby="enrollMasterPasswordResetTitle"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form
      class="modal-content"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="modal-header">
        <h2 class="modal-title" id="enrollMasterPasswordResetTitle">
          {{ "enrollPasswordReset" | i18n }}
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-callout type="warning">
          {{ "resetPasswordEnrollmentWarning" | i18n }}
        </app-callout>
        <app-user-verification [(ngModel)]="verification" name="secret"> </app-user-verification>
      </div>
      <div class="modal-footer">
        <bit-submit-button [loading]="form.loading">
          {{ "submit" | i18n }}
        </bit-submit-button>
        <button
          bitButton
          buttonType="secondary"
          type="button"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span>
            {{ "cancel" | i18n }}
          </span>
        </button>
      </div>
    </form>
  </div>
</div>
